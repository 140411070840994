import $ from "jquery";
window.jQuery = $;
window.$ = $;
import 'jquery.cookie'; 
import 'bootstrap';



import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
//= require bootstrap.bundle.min



Rails.start()
ActiveStorage.start()

// require("jquery-ui-dist");
require("jquery-ui-dist/jquery-ui");
require("front/bootstrap.bundle.min.js");
import 'lightbox2/dist/js/lightbox-plus-jquery.min';


require("front/bootstrap.bundle.min.js")

require("admin/jquery.maskedinput.js")
require("front/plugin.js")
require("front/main.js")
require("admin/jquery.toast.js")



require("admin/jquery.maskedinput.js")


$(document).ready(function(){
	$(".phone_no_masked").mask("99999 99999");

  //attach csrf token with every ajax
  $.ajaxSetup({
    headers: {
    'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
    }
  });
});

$(document).ready(function() {

  function handleDownload(url) {
      // Create a temporary <a> element
      const a = document.createElement('a');
      a.href = url;
      
      // Set the download attribute to trigger the download
      a.setAttribute('download', '');
  
      // Append the element to the body (necessary for Firefox)
      document.body.appendChild(a);
  
      // Programmatically click the <a> element to start the download
      a.click();
  
      // Remove the <a> element after the download starts
      document.body.removeChild(a);
  }
  

    // js for download form


    // Event listener for download button click
    $('.download-button').on('click', function(e) {
      const documentUrl = $(this).data('document-url');

      // Check if the 'download' cookie is set to 'true'
      if ($.cookie('dgrtgrthtg') == 'fsdffdsfdfdfdsfdfgthghghgfhfghgfhhhdfsdfsdfsdfsfsdfsd') {  
        handleDownload(documentUrl);
      } else {        
        e.preventDefault();
        $('#downloadModal').modal('show');
      }
    });
  
    $('#downloadForm').on('submit', function(e) {
      e.preventDefault(); // Prevent the default form submission
    
    
      const form = $(this);
      
      // Send the form data via AJAX
      $.ajax({
        url: form.attr('action'), // Use the form's action URL
        method: form.attr('method'), // Use the form's method (POST)
        data: form.serialize(), // Serialize the form data
        success: function(response) {
          console.log("form successfully saved");
    
          // Create a cookie valid for 24 hours only after successful form submission
          var clear_dt = new Date().getTime() + (24 * 60 * 60 * 1000);
          $.cookie('dgrtgrthtg', 'fsdffdsfdfdfdsfdfgthghghgfhfghgfhhhdfsdfsdfsdfsfsdfsd', { expires: new Date(clear_dt), path: '/' });
    
          // Hide the modal
          $('#downloadModal').modal('hide');
    
          // Initiate the file download
          const documentUrl = $('.download-button').data('document-url');
          handleDownload(documentUrl);
        },
        error: function(xhr, status, error) {
          console.log("form submission failed:", error);
          alert("There was an issue with your submission. Please try again.");
        }
      });
    });
  });

// pages image scroll 
  window.addEventListener('scroll', function() {
    const stickyImage = document.querySelector('.sticky-image');
    const scrollPosition = window.scrollY; // Get the current scroll position
  
    // Adjust the top position from 0 to 100px based on scroll
    if (scrollPosition <= 100) {
      stickyImage.style.top = `${scrollPosition}px`;
    } else {
      stickyImage.style.top = '100px'; // Max value
    }
    });



    (function () {
      var progressPath = document.querySelector(".progressParent path");
      var pathLength = progressPath?.getTotalLength();
      if (pathLength) {
        progressPath.style.transition = progressPath.style.WebkitTransition = "none";
        progressPath.style.strokeDasharray = pathLength + " " + pathLength;
        progressPath.style.strokeDashoffset = pathLength;
        progressPath.getBoundingClientRect();
        progressPath.style.transition = progressPath.style.WebkitTransition = "stroke-dashoffset 10ms linear";
        var updateProgress = function () {
          var scroll = $(window).scrollTop();
          var height = $(document).height() - $(window).height();
          var progress = pathLength - (scroll * pathLength) / height;
          progressPath.style.strokeDashoffset = progress;
        };
        updateProgress();
        $(window).scroll(updateProgress);
        var offset = 50;
        var duration = 550;
        jQuery(window).on("scroll", function () {
          if (jQuery(this).scrollTop() > offset) {
            jQuery(".progressParent").addClass("rn-backto-top-active");
          } else {
            jQuery(".progressParent").removeClass("rn-backto-top-active");
          }
        });
        jQuery(".progressParent").on("click", function (event) {
          event.preventDefault();
          jQuery("html, body").animate({ scrollTop: 0 }, duration);
          return false;
        });
      }
    })();

      /*-----------------------------------
    Sticky And Scroll Up
  -----------------------------------*/
  $(window).on("scroll", function () {
    var scroll = $(window).scrollTop();
    if (scroll < 400) {
      $(".header-sticky").removeClass("sticky-bar");
      $("#back-top").fadeOut(500);
    } else {
      $(".header-sticky").addClass("sticky-bar");
      $("#back-top").fadeIn(500);
    }
  });
